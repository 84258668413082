import React, { useEffect, useState } from "react";
import Card from "../../components/card/card";
import { NavLink, useParams } from 'react-router-dom';
import { connect } from "react-redux";
import moment from 'moment';
import "./spaces.css";
import { AnimatePresence, motion } from 'framer-motion';

function Spaces(props) {

    const { timeline } = useParams();
    const [filteredData, setFilteredData] = useState([]);

    const { contents = {} } = props;

    const _baseClass = "spaces";

    const formattedDate = moment(new Date());

    useEffect(() => {

        let filtered = timeline ? (contents?.items || []).filter((n) => {
            const formattedDate = moment(n.sys?.createdAt);
            return timeline === formattedDate.format("MMM-YYYY").toLowerCase()
        }) : contents?.items;

        setFilteredData(filtered || [])

    }, [timeline, contents])

    return (
        <div className={_baseClass + " m-[17.5px] sm:m-0"}>
            <div className="w-full flex flex-col gap-[10px]">

                <div className="w-full flex flex-row">
                    <div className="w-[50%] flex h-0 overflow-hidden sm:h-full sm:overflow-visible">
                        <div className="font-sofia-sb text-[45px] text-[#1C355E] font-black">SPACES</div>
                    </div>
                    <div className="w-[50%] flex flex-col justify-center h-0 overflow-hidden sm:h-full sm:overflow-visible">
                        <div className="w-full text-right" style={{ color: '#84818A', fontSize: 16, fontWeight: '500', wordWrap: 'break-word' }}>{formattedDate.format('dddd')}</div>
                        <div className="w-full text-right" style={{ color: '#2E2C34', fontSize: 14, fontWeight: '600', wordWrap: 'break-word' }}>{formattedDate.format('D MMMM YYYY')}</div>
                    </div>
                </div>


                <div className="grid grid-cols-12 gap-[10px] sm:gap-[30px]">
                    {(filteredData || []).map((news, index) => {

                        let cardStyle = index < 1
                            ? "col-span-12 md:col-span-6 initial-card h-[400px] sm:h-[470px]"
                            : (index < 2
                                ? " col-span-6 md:col-span-6 h-[270px] sm:h-[470px]"
                                : (index < 5
                                    ? " col-span-6 md:col-span-6 xl:col-span-4 h-[270px] sm:h-[470px]"
                                    : " col-span-6 md:col-span-6 xl:col-span-3 h-[270px] sm:h-[470px]"
                                )
                            )

                        return (
                            <NavLink to={"/article/" + news?.sys?.id} key={index} className={"transition-all duration-300 hover:shadow-md hover:cursor-pointer rounded-[16px] grid-cols-subgrid " +
                                cardStyle}>
                                <AnimatePresence>
                                    <motion.div
                                        className="h-full w-full"
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 1.5, ease: 'easeInOut' }}
                                    >
                                        <Card data={news} index={index} />
                                    </motion.div>
                                </AnimatePresence>
                            </NavLink>
                        )
                    })}
                </div>

            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    contents: state.contents
});

export default connect(mapStateToProps)(Spaces)