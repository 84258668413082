import React from "react";
import "./article.css";
import ArticleDetail from "../../components/articleDetail/articleDetail";
import {
    useParams
} from "react-router-dom";
import { connect } from "react-redux";

function Article(props) {
    const { contents = {} } = props;

    const _baseClass = "article";
    let { id } = useParams();

    return (
        <div className={_baseClass + " sm:mt-[50px]"}>
            <div className="w-full flex flex-col gap-[30px]">

                <div className="xl:gap-[30px]">
                    <ArticleDetail data={contents?.items?.filter(n => n.sys.id === id)[0]} />
                </div>

            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    contents: state.contents
});

export default connect(mapStateToProps)(Article)