import React from 'react';
// import { NavLink } from 'react-router-dom';
import SidebarItem from "./sideBarItem";
import './sideMenu.scss'
import { connect } from 'react-redux';
import moment from 'moment/moment';
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../../config";

const msalInstance = new PublicClientApplication(msalConfig);

function SideMenu({ ...props }) {

    let dates = props.contents?.items?.map((n) => n.sys.createdAt)
    let dateFilters = dates.reduce((acc, cur) => {

        let exist = false;

        acc.forEach(element => {
            if (element.title === moment(cur).format("MMMM YYYY")) {
                exist = true
            }
        });

        if (!exist) {
            acc.push({
                title: moment(cur).format("MMMM YYYY"),
                time: cur,
                path: "/" + moment(cur).format("MMM-YYYY").toLowerCase()
            })
        }

        return acc
    }, []);

    let items = [
        {
            "title": "Spaces",
            "icon": "/assets/vector-icons/home.svg",
            "children": dateFilters,
            "open": true,
            "path": "/"
        },
        {
            "title": "Apps",
            "icon": "/assets/vector-icons/people.svg",
            "children": [
                {
                    "title": "Asite",
                    "link": "https://system.asite.com/",
                    "external": true
                },
                {
                    "title": "Zoho CRM",
                    "link": "https://crm.zoho.com",
                    "external": true
                },
                {
                    "title": "Punch",
                    "link": "https://hcm22.sapsf.com/login?company=kps",
                    "external": true
                },
                {
                    "title": "Kunskap",
                    "link": "https://kps.litmoseu.com",
                    "external": true
                }
            ]
        }
    ]

    const _baseClass = "sideMenu";
    const logout = async () => {
        await msalInstance.initialize()
        await msalInstance.handleRedirectPromise()
        msalInstance.clearCache();
        msalInstance.logoutRedirect();
    }

    return (
        <div className={_baseClass + " overflow-hidden transition-all border-[#F3EEF6] xl:border bg-white h-full fixed w-0 xl:w-[236px] "}>
            <div className='h-[70px] flex justify-center items-center'>

                {/* <NavLink to={"/"}>
                    <div className='text-[35px] text-[#1C355E] font-black'>Spaces</div>
                </NavLink> */}

            </div>

            <div className="sidebar">
                {items.map((item, index) => <SidebarItem key={index} item={item} />)}
            </div>
            <div onClick={logout} className="h-[50px] flex items-center justify-center w-full text-[#1c355e] bg-[#e7e7e8] cursor-pointer">
                Logout
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    contents: state.contents
});

export default connect(mapStateToProps)(SideMenu);
