import { useEffect } from 'react';

const useApiDataWithTabVisibility = (fetchData) => {

    useEffect(() => {
        const visibilityChangeHandler = () => {
            if (!document.hidden) {
                fetchData();
            }
        };

        fetchData();

        document.addEventListener('visibilitychange', visibilityChangeHandler);

        return () => {
            document.removeEventListener('visibilitychange', visibilityChangeHandler);
        };
    }, [fetchData]);

    return true

};

export default useApiDataWithTabVisibility;
