import React, { useEffect } from "react";
import './articleDetail.scss'
import Markdown from "react-markdown";
import rehypeRaw from 'rehype-raw'
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from 'framer-motion';
import ReactPlayer from "react-player";

function ArticleDetail({ data = {}, ...props }) {

    const navigate = useNavigate();

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    const _baseClass = "article-detail";
    const { asset, fields, sys } = data;

    const formattedDate = moment(sys?.createdAt);
    let format = "D MMM, YYYY"
    let mobileFormat = "MMM D, YYYY";

    const goBack = () => {
        navigate(-1)
    }
    //const embedUrl = getVideoEmbedUrl(fields?.postVideo);
    // const getVideoEmbedUrl = (url) => {
    //     try {
    //         const parsedUrl = new URL(url);
    //         const isYouTube = parsedUrl.hostname === 'www.youtube.com' || parsedUrl.hostname === 'youtube.com';
    //         const isVimeo = parsedUrl.hostname === 'vimeo.com';

    //         if (isYouTube) {
    //             // Extract YouTube video ID and return the embed URL
    //             const videoId = parsedUrl.searchParams.get('v');
    //             return `https://www.youtube.com/embed/${videoId}`;
    //         }

    //         if (isVimeo) {
    //             // Extract Vimeo video ID from path and return the embed URL
    //             const pathSegments = parsedUrl.pathname.split('/');
    //             const videoId = pathSegments[1] || pathSegments[2]; // handle URLs like 'https://vimeo.com/951918293/5a9187e2b6'
    //             return `https://player.vimeo.com/video/${videoId}`;
    //         }

    //         return null;
    //     } catch (e) {
    //         console.error('Invalid video URL:', e);
    //         return null;
    //     }
    // };
    return (
        <AnimatePresence>
            <motion.div
                className="bg-white-200 flex justify-center items-center h-full w-full"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, ease: 'easeInOut' }}
            >
                <div className={_baseClass + " text-left flex items-center p-[20px] pt-[10px] sm:rounded-xl sm:p-[24px] bg-[#FAFAFA] sm:bg-[#FFF]"}>

                    <div className="max-w-[800px] w-full mb-[50px]">

                        <img onClick={() => goBack()} src="/assets/vector-icons/group.svg" alt="Back" className="py-[10px] sm:hidden" />
                        <div className={_baseClass + "-content"}>
                            <div className={_baseClass + "-title text-[#84818a]"}>{fields?.postDepartmentList}</div>
                            <div className={_baseClass + "-info mt-[10px] text-[28px] sm:text-[32px] sm:mt-[30px] leading-9"}>{fields?.postTitle}</div>
                            <div className={_baseClass + "-time mt-[10px] text-[#141416]"}>{formattedDate.format(format)}</div>
                        </div>

                        <img className="Shape mt-[32px]" style={{ width: 1092, borderRadius: 4 }} src={asset?.fields?.file?.url || ''} alt="News Cover" />

                        <div className="text-[24px] text-[#2E2C34] mt-[20px] leading-8">{fields?.postSubtitle}</div>
                        {/* <div className={_baseClass + "-title text-[12px] mt-[20px] h-max overflow-visible sm:h-0 sm:hidden text-[#616161]"}>{fields?.postDepartmentList}</div> */}
                        {/* <div className={_baseClass + "-time h-max overflow-visible sm:hidden text-[#706F6F] mt-[10px]"}>{formattedDate.format(mobileFormat)}</div> */}

                        <div className="html-markup">
                            <Markdown rehypePlugins={[rehypeRaw]} children={fields?.postContent}></Markdown>
                        </div>

                        {
                            fields?.postVideo && (
                                <ReactPlayer
                                    className="video-container rounded-xl shadow-2xl"
                                    width="100%"
                                    height="500px"
                                    url={fields?.postVideo}
                                    playing={false}
                                    controls={true}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                />
                            )
                        }
                    </div>

                </div>
            </motion.div>
        </AnimatePresence>


    );
}

export default ArticleDetail