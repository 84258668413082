import React, { useState } from 'react';
import './menu.scss'
import { connect } from 'react-redux';
import SideMenuMobile from "../sideMenuMobile/sideMenu"

function Menu({ isOpen = false, setOpen = () => { } }) {

    let toggleOverflow = () => {
        if (window.innerWidth <= 1023) {
            // Toggle the no-scroll class based on the new state
            if (!isOpen) {
                document.body.classList.add('no-scroll');
            } else {
                document.body.classList.remove('no-scroll');
            }
        }
    }


    let open = () => {
        let navToggle = document.querySelector('.nav-toggle')
        let bars = navToggle.querySelectorAll('.bar')

        function toggleHamburger(e) {
            bars.forEach(bar => bar.classList.toggle('x'))
        }

        toggleOverflow()

        toggleHamburger();
        setOpen(!isOpen);
    }

    let collapseMenu = () => {

        if (window.innerWidth <= 1023) {
            let navToggle = document.querySelector('.nav-toggle')
            let bars = navToggle.querySelectorAll('.bar')

            function toggleHamburger(e) {
                bars.forEach(bar => bar.classList.toggle('x'))
            }
            toggleOverflow()

            // document.body.classList.toggle('no-scroll');
            toggleHamburger();
            setOpen(false)
        }
    }

    return (
        <div className="relative h-[60px] overflow-visible" >
            <div className={'nav fixed left-0 top-0 w-full bg-[#FAFAFA] p-[20px] overflow-hidden'}>
                <div className="nav-toggle fixed z-20 sm:hidden" onClick={open}>
                    <span className="bar"></span>
                    <span className="bar"></span>
                    <span className="bar"></span>
                </div>
                <div className='flex justify-center'>
                    <div className="flex sm:hidden">
                        <div className="font-sofia-sb text-[35px] text-[#1C355E] font-black leading-[25px]">SPACES</div>
                    </div>
                </div>
                <SideMenuMobile collapsed={!isOpen} collapseMenu={collapseMenu} />

            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    contents: state.contents
});

export default connect(mapStateToProps)(Menu);
