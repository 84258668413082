import { combineReducers } from 'redux';
import { FETCH_CONTENTS_REQUEST, FETCH_CONTENTS_SUCCESS, FETCH_CONTENTS_FAILURE } from './actions';
import { userReducer } from "./user"

const initialState = {
    items: [],
    isLoading: false,
    error: null,
};

const contentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CONTENTS_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case FETCH_CONTENTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                items: action.payload,
            };
        case FETCH_CONTENTS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default combineReducers({
    contents: contentsReducer,
    user: userReducer
});