const initialState = {
    items: [],
    isLogged: false,
    error: null,
};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_USER":
            return {
                ...state,
                ...action.payload
            };

        default:
            return state;
    }
};