import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import './section.css'
import Spaces from "../../pages/spaces/spaces";
import Article from "../../pages/article/article";
import { connect } from "react-redux";
import { AnimatePresence, motion } from 'framer-motion';

const prefetchImages = (urls) => {
    urls.forEach((url) => {
        const img = new Image();
        img.src = url;
    });
};

function Section({ isOpen, contents, user }) {
    const [loaded, setLoaded] = useState(false);
    const [logged, setLogged] = useState(false);

    useEffect(() => {
        if (user.isLogged) {
            setLogged(true);
        }
    }, [user]);

    useEffect(() => {
        if (logged) {
            setTimeout(() => {
                setLoaded(true)
            }, 1000)
        }
    }, [logged]);

    useEffect(() => {

        // Prefetch images
        const imageUrls = (contents?.items || []).map(
            (news) => news?.asset?.fields?.file?.url
        );
        prefetchImages(imageUrls);
    }, [contents]);

    const _baseClass = "section";

    return (
        <div className={_baseClass + (!isOpen ? ' collapsed pl-0 w-full ' : ' pl-[100%] lg:pl-[236px] w-full overflow-hidden')}>
            {loaded && (
                <AnimatePresence>
                    <motion.div
                        className="sm:rounded-[10px] mt-0 sm:m-[50px] sm:mt-[20px] lg:mt-[20px] min-h-[calc(100vh-60px)] sm:min-h-[calc(100vh-130px)] xl:min-h-[calc(100vh-70px)]"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0.5 }}
                        transition={{ duration: 1.5, ease: 'easeOut' }}
                    >
                        <Routes>
                            <Route path="/:timeline?" element={<Spaces />} /> {/* 👈 Renders at /app/ */}
                            <Route path="/article/:id" element={<Article />} /> {/* 👈 Renders at /app/ */}
                        </Routes>
                    </motion.div>
                </AnimatePresence>
            )}

            <AnimatePresence>
                <motion.div
                    className="bg-white-200 flex justify-center items-center h-full w-full absolute left-0 z-50"
                    initial={{ opacity: 1 }}
                    animate={{ opacity: 0, display: 'none' }}
                    transition={{ duration: 1.5, ease: 'easeInOut' }}
                >
                    <div className="flex justify-center items-center flex-col">
                        <img src="/assets/kps-loader.gif" alt="" className="h-[200px] w-[200px]" />
                    </div>
                </motion.div>
            </AnimatePresence>
        </div>
    );
}

const mapStateToProps = state => ({
    contents: state.contents,
    user: state.user
});

export default connect(mapStateToProps)(Section)