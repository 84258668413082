import { useState } from "react"
import { NavLink, useNavigate } from 'react-router-dom';

export default function SidebarItem({ item, collapseMenu = () => { } }) {


    let closeMenu = () => {
        collapseMenu(true)
    }

    const navigate = useNavigate();

    const [open, setOpen] = useState(item.open || false);

    const openParent = () => {
        setOpen(!open)
        if (item.path) {
            
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 1000);

            navigate(item.path);
            closeMenu();
        }
    }

    if (item.children) {
        return (
            <div className={open ? "sidebar-item open" : "sidebar-item"}>
                <div className="sidebar-title" onClick={() => setOpen(!open)}>
                    <span className="sidebar-item-text" onClick={() => openParent()}>
                        {item.icon && <span className="sidebar-item-icon"><img src={item.icon} alt="Menu Icon" /></span>}
                        <span className="sidebar-item-label">{item.title}</span>
                    </span>
                    <img className="sidebar-item-arrow" src="/assets/vector-icons/arrow-down.svg" alt="Arrow" />
                </div>
                <div className="sidebar-content">
                    {item.children.map((child, index) => <SidebarItem key={index} item={child} collapseMenu={collapseMenu} />)}
                </div>
            </div>
        )
    } else {

        if (item.path) {
            return (
                <NavLink onClick={closeMenu} to={item.path || "#"} activeclassname="active" className="sidebar-item-text">
                    {item.icon && <span className="sidebar-item-icon"><img src={item.icon} alt="Menu Icon" /></span>}
                    <span className="sidebar-item-label p-[12px] pl-[12px]">{item.title}</span>
                </NavLink>
            )
        }
        return (
            <a href={item.link || "#"} onClick={closeMenu} target={item.external ? "_blank" : "_self"} rel="noreferrer" activeclassname="active" className="sidebar-item-text">
                {item.icon && <span className="sidebar-item-icon"><img src={item.icon} alt="Menu Icon" /></span>}
                <span className="sidebar-item-label p-[12px] pl-[12px]">{item.title}</span>
            </a>
        )

    }
}