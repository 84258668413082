import React, { useState } from "react";
import SideMenu from "../sideMenu/sideMenu";
import Menu from "../menu/menu";
import Section from "../section/section";
import { fetchContents } from "../../redux/actions";
import { connect, useDispatch } from "react-redux";
import useApiDataWithTabVisibility from "../../useApiDataWithTabVisibility";
import Redirect from "../../pages/redirect/redirect";
import "./layout.scss"

function Layout(props) {

    const { user } = props;

    const _baseClass = "layout";
    const [collapsed, toggleCollapse] = useState(true);
    const [isOpen, setOpen] = useState(false);

    const dispatch = useDispatch()

    useApiDataWithTabVisibility(() => {
        dispatch(fetchContents());
    });


    if (!user.isLogged) {
        return <Redirect />
    }

    return (
        <div className={_baseClass + " bg-[#FAFAFA] sm:bg-[#F4F5F6]"}>
            {/* <SideMenu collapsed={collapsed} toggleCollapse={toggleCollapse} /> */}
            <Menu setOpen={setOpen} isOpen={isOpen} />
            <Section isOpen={isOpen}/>
        </div>
    );
}

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps)(Layout)