import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../../config";

const msalInstance = new PublicClientApplication(msalConfig);

function Redirect(props) {

    const dispatch = useDispatch()

    useEffect(() => {

        const handleLogin = async () => {
            try {
                await msalInstance.initialize()
                const res = await msalInstance.handleRedirectPromise()

                const accounts = msalInstance.getAllAccounts();
                if (accounts.length === 0) {
                    dispatch({
                        type: "SET_USER",
                        payload: {
                            isLogged: false
                        }
                    })
                    await msalInstance.loginRedirect();

                }

                if (res) {
                    dispatch({
                        type: "SET_USER",
                        payload: {
                            isLogged: true,
                            ...res
                        }
                    });

                    localStorage.setItem("kps-user", JSON.stringify(res))
                }

                if (localStorage.getItem("kps-user") && JSON.parse(localStorage.getItem("kps-user"))) {
                    let res = JSON.parse(localStorage.getItem("kps-user"));
                    dispatch({
                        type: "SET_USER",
                        payload: {
                            isLogged: true,
                            ...res
                        }
                    });
                }


            } catch (error) {
                console.log(error);
            }
        };

        handleLogin();

    }, [dispatch])


    return (
        <div className="bg-white-200 flex justify-center items-center h-screen w-screen">
            <div className="flex justify-center items-center flex-col">
                <img src="/assets/kps-loader.gif" alt="" className="h-[200px] w-[200px]" />
                {/* <div className="loader mt-[20px]"></div> */}
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    contents: state.contents,
    user: state.user

});

export default connect(mapStateToProps)(Redirect)