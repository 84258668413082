export async function getContents() {
    try {
        const response = await fetch("https://cdn.contentful.com/spaces/jfdnrd5l5y2z/environments/master/entries?access_token=cjMgLw0lsMRyAMVDq-XpnvcV5wJY1cZSJRMwljjxISs");

        if (!response.ok) {
            throw new Error('Failed to fetch content');
        }

        const responseData = await response.json();
        const items = responseData.items;
        const assets = responseData.includes.Asset;

        assets.forEach(asset => {
            items.forEach((item) => {
                if (item?.fields?.postCoverImage?.sys?.id === asset.sys.id) {
                    item.asset = asset;
                }
            });
        });
        items.sort((a, b) => parseFloat(new Date(b.sys.createdAt).getTime()) - parseFloat(new Date(a.sys.createdAt).getTime()));

        return items;
    } catch (error) {
        console.error('Error fetching content:', error);
        throw error;
    }
}