import React, { useEffect } from "react";
import moment from 'moment';
import './card.scss';

function Card({ data, index }) {
    const _baseClass = "card";
    const { asset, fields, sys } = data;
    const imageUrl = asset?.fields?.file?.url || '';
    const formattedDate = moment(sys?.createdAt);

    useEffect(() => {
        if (imageUrl) {
            const img = new Image();
            img.src = imageUrl;
        }
    }, [imageUrl]);

    return (
        <div className={_baseClass + " p-[12px] sm:p-[20px] w-full h-full flex flex-col rounded-[16px] bg-[#FFF]"}>
            <div className={_baseClass + "-image-container flex items-center" + (index === 0 ? " h-[250px]" : " h-[120px] sm:h-[250px]")}>
                <img className={_baseClass + "-image min-h-full w-full object-cover"} src={imageUrl} alt="" />
            </div>
            <div className={_baseClass + "-content flex flex-col mt-[12px] sm:mt-[20px] text-left"}>
                <div className={_baseClass + "-title font-semibold text-[12px] text-[#1C355E] sm:text-[14px] uppercase"}>{fields?.postDepartmentList}</div>
                <div className={_baseClass + "-info mt-[5px] w-full text-[18px] sm:mt-[20px] sm:text-[24px] line-clamp-2"}>{fields?.postTitle}</div>
                <div className={_baseClass + "-time mt-[5px] sm:mt-[30px] text-[12px]"}>{formattedDate.format('D MMM, YYYY')}</div>
            </div>
        </div>
    );
}

export default Card;
