import { getContents } from './api'; // Assuming your API function is in api.js

export const FETCH_CONTENTS_REQUEST = 'FETCH_CONTENTS_REQUEST';
export const FETCH_CONTENTS_SUCCESS = 'FETCH_CONTENTS_SUCCESS';
export const FETCH_CONTENTS_FAILURE = 'FETCH_CONTENTS_FAILURE';

export const fetchContentsRequest = () => ({
  type: FETCH_CONTENTS_REQUEST,
});

export const fetchContentsSuccess = (contents) => ({
  type: FETCH_CONTENTS_SUCCESS,
  payload: contents,
});

export const fetchContentsFailure = (error) => ({
  type: FETCH_CONTENTS_FAILURE,
  payload: error,
});

export const fetchContents = () => async (dispatch) => {
  dispatch(fetchContentsRequest());
  try {
    const contents = await getContents();
    dispatch(fetchContentsSuccess(contents));
  } catch (error) {
    dispatch(fetchContentsFailure(error));
  }
};